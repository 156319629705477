// .stationInfo {
//     text-align: center;
//     width: 350px;
//     height: 120px;
//     margin: 40px;
//     padding-right: 35px;
//     padding-bottom: 30px;
//     border: 1px solid #bbb;
//     color: white;
//     border-radius: 10px;
//     background: radial-gradient(rgb(157, 157, 246),(rgb(0, 0, 255)));
//     box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
// }

// .stationInfoBlue {
//     text-align: center;
//     width: 350px;
//     height: 120px;
//     margin: 40px;
//     padding-right: 35px;
//     padding-bottom: 30px;
//     border: 1px solid #bbb;
//     color: white;
//     border-radius: 10px;
//     background: radial-gradient(rgb(157, 157, 246),(rgb(0, 0, 255)));
//     box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
// }

// .stationInfoGreen {
//     text-align: center;
//     width: 400px;
//     height: 120px;
//     margin: 40px;
//     padding-right: 35px;
//     padding-bottom: 30px;
//     border: 1px solid #bbb;
//     color: white;
//     border-radius: 10px;
//     background: radial-gradient(rgb(169, 246, 157),(rgb(2, 254, 65)));
//     box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
// }

// .stationInfoLightBlue {
//     text-align: center;
//     width: 350px;
//     height: 120px;
//     margin: 40px;
//     padding-right: 35px;
//     padding-bottom: 30px;
//     border: 1px solid #bbb;
//     color: white;
//     border-radius: 10px;
//     background: radial-gradient(rgb(75, 255, 249),(rgb(0, 251, 255)));
//     box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
// }

// .stationInfoYellow {
//     text-align: center;
//     width: 350px;
//     height: 120px;
//     margin: 40px;
//     padding-right: 35px;
//     padding-bottom: 30px;
//     border: 1px solid #bbb;
//     color: rgb(255, 255, 255);
//     border-radius: 10px;
//     background: radial-gradient(rgb(255, 251, 0),(rgba(237, 222, 57, 0.897)));
//     box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
// }

// .stationInfoPurple {
//     text-align: center;
//     width: 350px;
//     height: 120px;
//     margin: 40px;
//     padding-right: 35px;
//     padding-bottom: 30px;
//     border: 1px solid #bbb;
//     color: white;
//     border-radius: 10px;
//     background: radial-gradient(rgb(254, 48, 223),(rgb(255, 0, 170)));
//     box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
// }

.stationInfoBase {
    text-align: center;
    width: 80vw;
    max-width: 300px;
    height: 80px;
    margin: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    border: 1px solid #bbb;
    color: white;
    border-radius: 10px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);

    @media (max-width: 768px) {
        margin: 1rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .title {
        font-size: 40px;

        @media (max-width: 768px) {
            font-size: 24px;
        }
    }

    .description {
        font-size: 10px;

        @media (max-width: 768px) {
            font-size: 8px;
        }
    }
}

.stationInfoBlue {
    @extend .stationInfoBase;
    background: radial-gradient(rgb(157, 157, 246),(rgb(0, 0, 255)));
}
.stationInfoLightBlue {
    @extend .stationInfoBase;
    background: radial-gradient(rgb(75, 255, 249),(rgb(0, 251, 255)));
}
.stationInfoPurple {
    @extend .stationInfoBase;
    background: radial-gradient(rgb(254, 48, 223),(rgb(255, 0, 170)));
}

.stationInfoGreen {
    @extend .stationInfoBase;
    background: radial-gradient(rgb(169, 246, 157),(rgb(2, 254, 65)));
}

.stationInfoYellow {
    @extend .stationInfoBase;
    background: radial-gradient(rgb(255, 251, 0),(rgba(237, 222, 57, 0.897)));
}